require( 'slick-carousel' );

$( 'document' ).ready( function() {
  $( '.landing-wrapper .background-images' ).slick({
    slidesToShow:  1,
    fade:          true,
    arrows:        false,
    dots:          false,
    autoplay:      true,
    autoplaySpeed: 5500,
  });

  $( '.landing-wrapper .background-images' ).removeClass( 'hide' );

  $( window ).resize( function() {
    if ( $( window ).width() < 1200 ) {
      let content = $( '.landing-inner .content' );
      let selector = $( '.landing-inner .region-selector' );
      let calculatedH = $( window ).height() - content.outerHeight( true ) -
        selector.outerHeight( true );
      $( '.background-images-wrapper .slick-slide' ).css( 'height', calculatedH + 'px' );
    }
  }).resize();

});
