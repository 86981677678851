/* global Foundation */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
let targetElement = document.querySelector( '.chr-black-overlay' );

const $body = $( 'body' );
const $window = $( window );
const navScrollDistance = 0;

// Search Form Element
const $searchToggleBtn = jQuery( '#chr-search-toggle-button' );
const $searchForm = jQuery( '.chr-search-form-wrapper' );

// Sidebar Cart
const $sidebarCartIcon = jQuery( '.button--cart' );
const $sidebarCart = jQuery( '.sidebar-cart' );

const $hamburger = jQuery( '.chr-hamburger' );
const $mainMenu = jQuery( '.chr-main-menu' );

const $blackOverlay = jQuery( '.chr-black-overlay' );

const scrollHeader = () => {

  let st = jQuery( window ).scrollTop();
  if ( st > navScrollDistance ) {
    $body.addClass( 'scroll' );
  } else {
    $body.removeClass( 'scroll' );
  }

};

// Toggle Menus

const OpenSearchForm = () => {
  $searchForm.slideDown();
  $searchToggleBtn.addClass( 'close' );
  $body.addClass( 'search-form-open' );

  targetElement = document.querySelector( '.chr-search-form-wrapper' );
  disableBodyScroll( targetElement );
};

const CloseSearchForm = () => {
  $searchForm.slideUp();
  $searchToggleBtn.removeClass( 'close' );
  $body.removeClass( 'search-form-open' );

  enableBodyScroll( targetElement );
};

const OpenSideBarCart = () => {
  $sidebarCart.addClass( 'open' );

  targetElement = document.querySelector( '.sidebar-cart' );
  disableBodyScroll( targetElement );
};

const CloseSideBarCart = () => {
  $sidebarCart.removeClass( 'open' );
  enableBodyScroll( targetElement );
};

const OpenResponsiveMainMenu = () => {
  $mainMenu.addClass( 'open' );
  $hamburger.attr( 'aria-expanded', 'true' );

  targetElement = document.querySelector( '.chr-main-menu' );
  disableBodyScroll( targetElement );
};

const CloseResponsiveMainMenu = () => {
  $mainMenu.removeClass( 'open' );
  $hamburger.attr( 'aria-expanded', 'false' );

  enableBodyScroll( targetElement );
};

// Actions

const toggleSearchFormAction = () => {

  if ( ! $searchToggleBtn.length ) {
    return;
  }

  $searchToggleBtn.click( () => {

    if ( $searchToggleBtn.hasClass( 'close' ) ) {
      CloseSearchForm();
      $blackOverlay.fadeOut();
    } else {
      CloseSideBarCart();
      CloseResponsiveMainMenu();
      OpenSearchForm();
      $blackOverlay.fadeIn();
    }

  });

};

const toggleSideBarCartAction = () => {

  if ( ! $sidebarCart.length ) {
    return;
  }

  $sidebarCartIcon.click( () => {

    if ( $sidebarCart.hasClass( 'open' ) ) {
      CloseSideBarCart();
      $blackOverlay.fadeOut();
    } else {
      CloseSearchForm();
      CloseResponsiveMainMenu();
      OpenSideBarCart();
      $blackOverlay.fadeIn();
    }

  });

};

const toggleResponsiveMainMenuAction = () => {

  $hamburger.click( () => {

    if ( Foundation.MediaQuery.is( 'large' ) ) {
      return;
    }

    if ( $mainMenu.hasClass( 'open' ) ) {
      CloseResponsiveMainMenu();
      $blackOverlay.fadeOut();
    } else {
      CloseSideBarCart();
      CloseSearchForm();
      OpenResponsiveMainMenu();
      $blackOverlay.fadeIn();
    }

  });

};

const closeAll = () => {

  $blackOverlay.click( () => {
    CloseSearchForm();
    CloseResponsiveMainMenu();
    CloseSideBarCart();
    $blackOverlay.fadeOut();
  });

};

$( document ).ready( () => {

  toggleSearchFormAction();
  toggleSideBarCartAction();
  toggleResponsiveMainMenuAction();

  closeAll();

  /*Handle some scroll events*/
  $window.scroll( () => {

    scrollHeader();

  }).scroll();

});
