/*
 * Main JS file for JS imports.
 */

// Chromatix global variables
window.chrGlobal = {
  mobileNavBreak:   1024,
  headerHeightDesk: 70, // Height after scroll
  headerHeightMob:  60,
};

require( './global/load-effect' );
require( './global/header' );
require( './global/scroll-to' );
require( './global/landing' );

// Fix foundation resize from responsive to desktop, dropdown menu bug.
jQuery( window ).on( 'changed.zf.mediaquery', ( event, newSize, oldSize ) => {

  if ( newSize.includes( 'large' ) && ! oldSize.includes( 'large' ) ) {
    location.reload();
  }

});
