/* eslint no-magic-numbers: "off"*/

import ScrollMagic from 'scrollmagic';
import { TweenMax } from "gsap/TweenMax";

const controller = new ScrollMagic.Controller();

const generalFadeOnView = () => {

  let $fadeOnView = $( '.fadeOnView' );

  if ( ! $fadeOnView.length ) {
    return;
  }

  jQuery( '.fadeOnView' ).each( ( index, el ) => {

    new ScrollMagic.Scene({
      triggerElement: jQuery( el )[0],
      offset:         50,
      triggerHook:    0.9, // Move trigger to center of element
      reverse:        false // Only do once
    }).setClassToggle( jQuery( el )[0], 'show' ).addTo( controller );

  });

};

const oneByOneLoadEffect = () => {

  let $animOneByOne = $( '.one-by-one' );
  const interval = 0.3;

  $animOneByOne.each( ( index, el ) => {
    new ScrollMagic.Scene({
      triggerElement: jQuery( el )[0],
      offset:         50,
      triggerHook:    0.9, // Move trigger to center of element
      reverse:        false // Only do once
    }).addTo( controller )
      .on( 'start end', ( event ) => {
        if ( 'end' === event.type ) {
          return;
        }
        TweenMax.staggerTo(
          jQuery( el ).find( '.single-onebyone' ),
          1, { css: { className: '+=show' } },
          interval
        );
      });
  });
};

jQuery( () => {
  generalFadeOnView();
  oneByOneLoadEffect();
});
