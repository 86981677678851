import animateScrollTo from 'animated-scroll-to';
const chr = window.chrGlobal;

const anchorClick = () => {

  $( "a[href^='#']" ).not( "a[href='#'], .tabs a" ).click( function() {
    animateScrollTo( document.querySelector( $( this ).attr( 'href' ) ), {
      offset:
        ( $( window ).width() < chr.mobileNavBreak ) ? -chr.headerHeightMob : -chr.headerHeightDesk,
      speed: 1000,
    });
  });

};

$( document ).ready( () => {
  anchorClick();
});
